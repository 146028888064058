import * as React from 'react';
import { useRef, useEffect } from 'react';
import clamp from 'lodash-es/clamp';
import { useSprings, animated, useSpring, easings, config as SpringConfig } from 'react-spring';
import { useGesture, useDrag } from '@use-gesture/react';
import './pager.scss';
import HomePage from './home';
import SAPage from './smart-assistant';
import SRPage from './smart-rep';
import MyHealthBookPage from './home-myhealthbook';
import Layout from '../components/layout';

const isBrowser = typeof window !== 'undefined';

const pages = [ (<HomePage />), (<SAPage />), (<SRPage />), (<MyHealthBookPage />) ];

function VerticalViewpager() {
  const index = useRef(0);
  const windowHeight = isBrowser ? window.innerHeight : 900;
  const [ props, api ] = useSprings(pages.length, i => ({ y: i * windowHeight, sc: 1, display: 'block' }));


  const handlerFn = ({ dragging: down, movement: [ xDelta, yDelta ], direction: [ xDir, yDir ], distance: [ xDistance, yDistance ], cancel }) => {
    const distanceMoved = 100;
    // const distanceMovedRatio = 0.1;
    // console.log('testing:', down, yDelta, yDir, yDistance, index.current);
    // if (down && yDistance > (distanceMoved)) {
    //   console.log(index.current);
    //   (index.current = clamp(index.current + (yDir > 0 ? -1 : 1), 0, pages.length - 1));
    //   cancel();
    // }
    if (!down && yDistance > distanceMoved) {
      console.log('testing:', down, yDelta, yDir, yDistance, index.current);
      let direction = yDir;
      if (direction === 0) {
        direction = (yDelta < 0) ? -1 : 1;
      }
      (index.current = clamp(index.current + (direction > 0 ? -1 : 1), 0, pages.length - 1));
    }
    api.start(i => {
      if (i < index.current - 1 || i > index.current + 1) {
        return { display: 'none' };
      }
      const y = (i - index.current) * windowHeight + (down ? yDelta : 0);
      const sc = down ? 1 - yDistance / (distanceMoved) : 1;
      const config = { ...SpringConfig.gentle, easing: easings.easeInOutExpo };
      return { y, sc, display: 'block' };
    });
  };

  // const bind = useGesture({onDrag: handlerFn});
  const bind = useDrag(handlerFn);
  return props.map(({ y, display, sc }, i) => (
    <animated.div {...bind()} key={i}
                  style={{ display, transform: y.to(y => `translate3d(0,${y}px,0)`), touchAction: 'none' }}>
      {pages[i]}
    </animated.div>
  ));
}

function AxisExample() {
  const [ { x }, api ] = useSpring(() => ({ x: 0 }));
  const bind = useDrag(({ down, movement: [ mx ] }) => api.start({ x: down ? mx : 0 }), { axis: 'x' });
  return <animated.div {...bind()} style={{ x, width: 200, height: 300, backgroundColor: 'blue' }} />;
}


const Pager = () => {
  return (
    <Layout>
      <div id="root">
        <VerticalViewpager />
      </div>
    </Layout>

  );
};

const MainPage = () => {

  useEffect(() => {
    console.log('dom loaded');
    setTimeout(() => {
      // window.scrollTo(0, 1000);
    }, 2000);
  });

  return (
    <Layout>
      <div className='main-item-container'>
        <div className='main-page-item'>
          <HomePage />
        </div>
        <div className='main-page-item'>
          <SAPage />
        </div>
        <div className='main-page-item'>
          <SRPage />
        </div>
        <div className='main-page-item'>
          <MyHealthBookPage />
        </div>
      </div>


    </Layout>
  );
};

export default MainPage;
